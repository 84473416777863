import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 注入百度地图vue-baidu-map
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: '0il2VOF8v11AMi8DQqZAFLsX8hBRsRMR'
})

// 引入swiper插件
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

// 引入wow动画
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
Vue.use(WOW);
Vue.prototype.$WOW = WOW;

// 图片懒加载引用
import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad, {
  preLoad: 1,
  error: require('./assets/images/global/error.png'),
  loading: require('./assets/images/global/loading.gif'),
  attempt: 2,
})


import "@assets/css/reset.css";
Vue.config.productionTip = false

// 多语言
import i18n from './language'


const $vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
})

setTimeout(() => {
  $vm.$mount("#app");
}, 300);
