
// 中文-简体
export default {
  imgUrl: require('../../assets/images/global/case_01.jpg'),
  platformName: 'vue-i18n + element-ui 多语言配置',
  logIn: '登录',
  logOut: '退出',
  register: '注册',
  normalLogin: '普通登录',
  phoneLogin: '手机快捷登录',
  userName: '用户名',
  password: '密码',
  phone: '手机号',
  verificationCode: '验证码',
  getCode: '获取验证码',
  enterCode: '请输入验证码',
  enterPhone: '请输入手机号码',
  enterUserName: '请输入用户名',
  enterPassword: '请输入密码',
  enterLoginPassword: '请输入登录密码',
  enterRightPhone: '请输入正确的手机号码',
  title: '随意起的标题展示'
}
