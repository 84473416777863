<style lang="scss" scoped>
$bgWhiteColor:#fff;
.Header,.mainHeader,.HeaderBox {
  height:$headerHeight;
  width:100%;
  box-sizing: border-box;
}
.HeaderBox{
  border-bottom: 1px solid #eee;
  min-width:$headerwidth;
  background-color:$bgWhiteColor;
  z-index: 9;
  position: fixed;
  left: 0;
  top: 0;
}
.mainHeader{
  width:$headerwidth;
  margin:0 auto;

  .image {
    width:140px;
    height:54px;
    display:block;
    padding-top:6px;
  }
  .headerLIst {
    margin-left:158px;
    .item {
      margin-left:74px;
      cursor: pointer;
      position: relative;
    }
    .line{
      width:100%;
      height:3px;
      background-color:red;
      position:absolute;
      left:0;
      bottom:0;
      display:none;
    }
    .item:hover .line,.item.active .line{
      display:block;
    }
    .item:hover .childrenList{display:block;}
    .childrenList{
      width:200px;
      position:absolute;
      top:$headerHeight;
      left:-20px;
      background-color:#fefefe;
      z-index:9;
      display: none;
      border-radius: 2px;
      .childrenBox{
        width:100%;
        margin:0 auto;
        text-align: center;
        font-size:15px;
      }
      .childrenListItem{
        cursor: pointer;
        line-height:55px;
      }
      .childrenListItem:hover{
        background-color: #ddd;
      }
      // .childrenListItem:hover {
      //   text-decoration: underline;
      // }
    }
  }
  .language{
    margin-left:80px;
    .el-dropdown{
      cursor: pointer;
      position: relative;
    }
  }
}
.toTop{
  position: fixed;
  right: 15vh;
  bottom: 15vh;
  z-index: 999;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}
</style>

<template>
  <div class="Header">
    <div class="HeaderBox">
      <div class="mainHeader acea-row" id="headerLIst">
        <img src="@/assets/images/global/logo_header.png" class="image" />
        <div class="headerLIst acea-row">
          <div class="item acea-row row-between-wrapper" :class="[urlPath==headerList[0].url?'active':'']" @click="select(0)">
            首页
            <div class="line"></div>
            <!-- 果果慧首页的子菜单栏 -->
            <div class="childrenList" v-if="headerList[0].children">
              <div class="childrenBox ">
                <div class="childrenListItem "
                  v-for="(itemX, indexX) in headerList[0].children"
                  :key="indexX"
                  @click.stop="secondSelect(headerList[0],indexX,0)"
                >
                  {{itemX.child_name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item acea-row row-between-wrapper" :class="[urlPath==headerList[1].url?'active':'']" @click="select(1)">
            果果慧业务
            <div class="line"></div>
            <!-- 果果慧业务中的子菜单栏 -->
            <div class="childrenList" v-if="headerList[1].children">
              <div class="childrenBox" >
                <div class="childrenListItem"
                  v-for="(itemX, indexX) in headerList[1].children"
                  :key="indexX"
                  @click.stop="secondSelect(headerList[1],indexX,1)"
                >
                  {{itemX.child_name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item acea-row row-between-wrapper" :class="[urlPath==headerList[2].url?'active':'']" @click="select(2)">
            果果慧故事
            <div class="line"></div>
            <!-- 果果慧故事的子菜单栏 -->
            <div class="childrenList" v-if="headerList[2].children">
              <div class="childrenBox">
                <div class="childrenListItem"
                  v-for="(itemX, indexX) in headerList[2].children"
                  :key="indexX"
                  @click.stop="secondSelect(headerList[2],indexX,2)"
                >
                  {{itemX.child_name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item acea-row row-between-wrapper"  @click="select(3)">
            线上商城
            <div class="line"></div>
            <!-- 线上商城的子菜单栏 -->
            <div class="childrenList" v-if="headerList[3].children">
              <div class="childrenBox">
                <div class="childrenListItem"
                  v-for="(itemX, indexX) in headerList[3].children"
                  :key="indexX"
                  @click.stop="secondSelect(headerList[3],indexX,3)"
                >
                  {{itemX.child_name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item acea-row row-between-wrapper" :class="[urlPath==headerList[4].url?'active':'']" @click="select(4)">
            加入我们
            <div class="line"></div>
            <!-- 加入我们的子菜单栏 -->
            <div class="childrenList" v-if="headerList[4].children">
              <div class="childrenBox">
                <div class="childrenListItem"
                  v-for="(itemX, indexX) in headerList[4].children"
                  :key="indexX"
                  @click.stop="secondSelect(headerList[4],indexX,4)"
                >
                  {{itemX.child_name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item acea-row row-between-wrapper" :class="[urlPath==headerList[5].url?'active':'']" @click="select(5)">
            联系我们
            <div class="line"></div>
          </div>
        </div>
        <div class="language acea-row row-center-wrapper">
          <el-dropdown trigger="click" @command="changeLanguage">
            <span class="lang acea-row row-center-wrapper">
              <img :src="languageImg" alt="" style="margin-right:10px;font-size:0;">
              <span v-text="currentLanguage"></span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in list"
                :key="index"
                :command="item"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <img @click="toTopF()" class="toTop" src="@/assets/images/global/toTop.png" alt="" :style="{'display': toTop? 'block':'none'}">
  </div>
</template>

<script>
import list from '@/language/languageList';
export default {
  /* 头部导航栏 */
  name: "Header",
  // 接收传递过来的参数---为了验证此时的页面路由地址展示不同的头部状态
  props: {
    url:{
      type: [String],
      default: '/'
    },
    id: {
      type: [String,Number,Object],
      default:null
    },
  },
  data() {
    return {
      urlPath: '',
      // 是否有跳出到其他网址
      onlineUrl: false,
      headerList:[
        { name: '首页', url: '/', type: 1 },
        {
          name: '果果慧业务',
          url: '/business',
          type: 2,
          children:[
            {
              child_name: '嵌入式智慧产品解决方案',
              id:'product'
            },
            {
              child_name: '智慧农业',
              id:'agriculture'
            },
            {
              child_name: '智慧传感器通讯产品',
              id:'sensor',
            }
          ]
        },
        {
          name: '果果慧故事',
          url: '/story',
          type: 2,
          children:[
            {
              child_name: '公司简介',
              id:'story'
            },
            {
              child_name: '企业文化',
              id:'guideObjective',
            },
            {
              child_name: '发展历程',
              id:'developmenet'
            },
            {
              child_name: '果果慧荣誉',
              id:'honor'
            }
          ]
        },
        { name: '线上商城', url: '', type:0, onlineUrl:'https://axelinxsm.tmall.hk'},
        {
          name: '加入我们',
          url: '/join',
          type: 2,
          children:[
            {
              child_name: '人才招聘',
              id:'recruit'
            },
            {
              child_name: '福利待遇',
              id:'welfare'
            }
          ]
        },
        { name: '联系我们', url: '/concat', type: 1 },
      ],
      // 把头部banner固定改成fixed--true表示正常输出流  false表示脱离文档输出流
      // headerStatus: true,
      // false表示正常隐藏 true表示显现
      toTop: false,
      // 语言切换
      currentLanguage: '',
      languageImg:'',
      languageImg1:require('../assets/images/global/zhCN.png'),
      languageImg2:require('../assets/images/global/en.png'),
      languageImg3:require('../assets/images/global/jaJP.png'),
      aifrutech:'aifrutech',
      aifrutechIndex: (new Date()).getTime(),
      // 读取配置语言配置文件list,
      list: [],
    };
  },
  created () {
    let _that = this;
    _that.currentLanguage = localStorage.langName || '简体中文';
    _that.list = list;
    _that.$store.commit("SET_LANGUAGE", localStorage.lang);
    if(!localStorage.lang){
      _that.languageImg = _that.languageImg1;
    }else{
      _that.languageImg = localStorage.lang == 'zhCN'? _that.languageImg1:(localStorage.lang == 'en'?_that.languageImg2:_that.languageImg3);
    }

  },
  watch: {
    // 时刻监听此时的路由地址
    $route(to, from){
      this.urlPath = this.$route.path;
    },
  },
  mounted(){
    window.addEventListener("scroll",this.handleScroll);
    // 初始化获得数据
    this.$set(this, "urlPath", this.$route.path);
  },
  methods: {
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop)
      // if (scrollTop > 33) {
      //   //滚动大于0的时候要做的操作
      //   this.headerStatus = false
      // }else{
      //   this.headerStatus = true
      // }
      (scrollTop > 150) ?(this.toTop = true):(this.toTop = false)
    },
    select(index){
      // console.log(index)
      let _that = this,headerList = _that.headerList;
      if (headerList[index].type == 0) {
        window.open(headerList[index].onlineUrl, '_blank');
        _that.urlPath = headerList[index].onlineUrl;
        _that.onlineUrl = true;
      } else {
        _that.$router.push({ path: headerList[index].url });
        _that.urlPath = headerList[index].url;
        _that.onlineUrl = false;
      }
    },
    secondSelect(data,index,outIndex){
      let _that = this,url = data.url,headerList = _that.headerList;
      _that.aifrutechIndex ++;
      _that.urlPath = headerList[outIndex].url;
      // 获取到的父路由地址，和子参数
      _that.$router.push({ path: url, query:{ type: data.children[index].id+_that.aifrutech+_that.aifrutechIndex,}});
    },

    // 语言的切换
    changeLanguage(item) {
      let _that = this;
      _that.languageImg = (item.value == 'zhCN'?_that.languageImg1:(item.value == 'en'?_that.languageImg2:_that.languageImg3));
      _that.$store.commit("SET_LANGUAGE", item.value);
      _that.currentLanguage = item.label;
      // this.bodyAddClassName(item.value);
      _that.$i18n.locale = item.value;
      localStorage.setItem("langName", item.label);
      localStorage.setItem("lang", item.value);
      // header中的修改信息传递除去给app.vue
      _that.$emit('listenLanguage', item)
    },

    // 修改body的class
    bodyAddClassName(className) {
      let dom = document.getElementById("app");
      dom.className = `language_${className}`;
    },
    // 回到顶部
    toTopF(){
      window.scrollTo({ top: 0,left: 0,behavior: 'smooth' });
    }
  }
};
</script>
