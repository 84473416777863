<template>
  <div id="app">
    <Header v-on:listenLanguage="getLanguage"></Header>
    <router-view/>
    <Footer :language="language"></Footer>
  </div>
</template>

<style lang="scss">
#app {
  // font-family: '微软雅黑',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<script>
import Header from '@components/Header';
import Footer from '@components/Footer';
import { GetQueryValue } from "@utils/index";
export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      url: '/',// 当前页面地址
      language:{},// 当前header中设置的语言
    }
  },
  created () {
  },
  watch: {
    $route(to, from) {
      // console.log(to)
      // console.log(from)
    }
  },
  mounted(){
  },
  methods: {
    getLanguage(data) {
      this.language = data;
    },
  },
  destroyed () {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
}

</script>

