<style lang="scss" scoped>
#footer {
  width:100%;
  height:324px;
  min-width: $footerwidth;
  position: relative;
  font-size:$Size14;
  background:url('../assets/images/global/footer_bg.png') no-repeat;
  background-position:center bottom;
  background-color: #14294d;
  .footerMain{
    width:$footerwidth;
    margin: 0 auto;
    padding-top:60px;
    height:240px;
    border-bottom:1px solid #2c3e5d;
    .footerCompany{float: left;}
    .footerCompany,.footerCompany div{
      width: 158px;
      color:$footerColor1;
      text-align:right;
    }
    .img{width:158px;height:60px;margin-bottom:2px;}
    .footerTitles{float:left;margin-left:82px;}
    .footerTitles .item{
      width: 138px;
    }
    .footerTitles .item.margin_left{
      margin-left:26px;
    }
    .footerAddress{
      margin-left:38px;
      .item_title{
        color:$footerColor2;
      }
    }
    .item{
      color:$footerColor1;
    }
    .item_title{
      line-height:36px;
      cursor: pointer;
    }
    .item_title_second{
      font-size:$Size12;
      color:$footerColor2;
      line-height:22px;
      cursor: pointer;
    }
  }
  .rull{
    width:$footerwidth;
    min-width: $footerwidth;
    margin:18px auto 0;
    a,a:active,a:hover,a:visited,a:focus{
      color:$footerColor2 !important;
    }
    div{
      font-size:$Size12;
      color:$footerColor2;
    }
    .rull_item{
      padding:0 18px;
      cursor: pointer;
      position: relative;
      i{
        width:1px;
        height:8px;
        background-color: #737984;
        position: absolute;
        right: 0;
        bottom: 4px;
      }
    }
  }

}
</style>

<template>
  <div class="footer" id="footer">
    <div class="footerMain">
      <div class="footerCompany">
        <img class="img" src="@/assets/images/global/logo_footer.png" alt="">
        <div class="footerCompany_yj">
          <div>搭建技术桥梁</div>
          <div>创造幸福社会</div>
        </div>
      </div>
      <div class="footerTitles acea-row">
        <div class="item" :class="[index==2?'margin_left':'']" v-for="(item, index) in footerList" :key="index">
          <div class="item_title"  @click="select(index)">{{item.name}}</div>
          <div v-if="item.children.length>0" class="item_title_second">
            <div v-for="(itemX, index2) in item.children" :key="index2" @click.stop="secondSelect(item,index2,index)">{{itemX.child_name}}</div>
          </div>
        </div>
      </div>
      <div class="footerAddress acea-row">
        <div class="item">
          <div class="item_title">深圳果果慧科技有限公司</div>
          <div class="item_title_second">地址：深圳南山区朗山路13号紫光信息港B座3层B301室</div>
          <div class="item_title_second">电话：0755-8603-6686</div>
          <div class="item_title_second">邮箱：hr@aifrutech.com</div>
        </div>
      </div>
    </div>
    <div class="rull acea-row row-between">
      <div class="rullLInk acea-row row-between-wrapper">
        <div class="rull_item" style="padding-left:0;"><router-link :to="'law'">法律声明</router-link><i></i></div>
        <div class="rull_item"><router-link :to="'privacy'">隐私策略</router-link><i></i></div>
        <div class="rull_item"><router-link :to="'clause'">使用条款</router-link><i></i></div>
        <div class="rull_item"><router-link :to="'feedback'">网站反馈</router-link><i></i></div>
        <a class="rull_item" target="_blank" href="http://beian.miit.gov.cn/">粤ICP备18122215号<i></i></a>
        <div class="rull_item noBorder">版权所有©2020深圳果果慧科技有限公司</div>
      </div>
      <div class="language acea-row row-center-wrapper">
        <img
          :src='(languageImg&&!language.value)?languageImg:((language.value=="zhCN") ? languageImg1:(language.value=="en"?languageImg2:languageImg3))'
          alt="" style="margin-right:10px;">
        <div>{{language.label || languageTitle}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  /* 多语言切换 */
  name: "Footer",
  // 接收传递过来的参数---为了验证此时的页面路由地址展示不同的头部状态
  props: {
    language: {
      type: Object,
      default: {
        label: '',
        value: ''
      }
    },
  },
  data () {
    return {
      languageImg:'',
      languageImg1:require('../assets/images/global/zhCN.png'),
      languageImg2:require('../assets/images/global/en.png'),
      languageImg3:require('../assets/images/global/jaJP.png'),
      languageTitle:'简体中文',
      footerList:[
        {
          name: '首页',
          url: '/',
          type: 1,
          children:[
            {
              child_name: '业务领域',
              id:'business'
            },
            {
              child_name: '成功案例',
              id:'case'
            },
            {
              child_name: '果果慧优势',
              id:'advantage',
            },
            {
              child_name: '业务流程',
              id:'process',
            },
            {
              child_name: '新闻资讯',
              id:'news',
            }
          ]
        },
        {
          name: '果果慧业务',
          url: '/business',
          type: 2,
          children:[
            {
              child_name: '嵌入式智慧产品解决方案',
              id:'product'
            },
            {
              child_name: '智慧农业',
              id:'agriculture'
            },
            {
              child_name: '智慧传感器通讯产品',
              id:'sensor',
            }
          ]
        },
        {
          name: '果果慧故事',
          url: '/story',
          type: 2,
          children:[
            {
              child_name: '公司简介',
              id:'story'
            },
            {
              child_name: '企业文化',
              id:'guideObjective',
            },
            {
              child_name: '发展历程',
              id:'developmenet'
            },
            {
              child_name: '果果慧荣誉',
              id:'honor'
            }
          ]
        },
        { name: '线上商城', url: '', type:0, onlineUrl:'https://axelinxsm.tmall.hk', children:[{child_name:'天猫平台',url:'https://axelinxsm.tmall.hk'}] },
        {
          name: '加入我们',
          url: '/join',
          type: 2,
          children:[
            {
              child_name: '人才招聘',
              id:'recruit'
            },
            {
              child_name: '福利待遇',
              id:'welfare'
            }
          ]
        },
      ],
      aifrutech:'aifrutech',
      aifrutechIndex: (new Date()).getTime(),
    }
  },
  created () {
    let _that = this;
    _that.languageTitle = localStorage.langName || '简体中文';
    if(!localStorage.lang){
      _that.languageImg = _that.languageImg1;
    }else{
      _that.languageImg = localStorage.lang == 'zhCN'? _that.languageImg1:(localStorage.lang == 'en'?_that.languageImg2:_that.languageImg3);
    }
  },
  methods:{
    select(index){
      console.log(index)
      let _that = this,headerList = _that.footerList;
      if (headerList[index].type == 0) {
        window.open(headerList[index].onlineUrl, '_blank');
        _that.urlPath = headerList[index].onlineUrl;
        _that.onlineUrl = true;
      } else {
        _that.$router.push({ path: headerList[index].url });
        _that.urlPath = headerList[index].url;
        _that.onlineUrl = false;
      }
    },
    secondSelect(data,index,outIndex){
      let _that = this,url = data.url,footerList = _that.footerList;
      _that.aifrutechIndex ++;
      _that.urlPath = footerList[outIndex].url;
      // 获取到的父路由地址，和子参数
      if(footerList[outIndex].type== 0){
        window.open(data.children[index].url, '_blank');
      }else{
        _that.$router.push({ path: url, query:{ type: data.children[index].id+_that.aifrutech+_that.aifrutechIndex,}});
      }
    },
  }
}
</script>
