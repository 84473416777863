import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from "../store";

Vue.use(VueRouter)

export const constantRouterMap = [
  // { path: '/test', name: 'test', component: () => import('../views/login/index.vue') },
  // 首页
  { path: '/', name: 'index', meta: { title: '首页' }, component: () => import('../views/index.vue') },
  // 果果慧业务
  { path: '/business', name: 'business', meta: { title: '果果慧业务' }, component: () => import('../views/business.vue') },
  // 果果慧业务---嵌入式操作系统
  { path: '/OS_solutions', name: 'OS_solutions', meta: { title: '嵌入式操作系统' }, component: () => import('../views/OS_solutions.vue') },
  // 果果慧业务---图像识别
  { path: '/image_recognition', name: 'image_recognition', meta: { title: '图像识别' }, component: () => import('../views/image_recognition.vue') },
  // 果果慧业务---视频处理
  { path: '/video_processing', name: 'video_processing', meta: { title: '视频处理' }, component: () => import('../views/video_processing.vue') },
  // 果果慧业务---语音识别
  { path: '/speech_recognition', name: 'speech_recognition', meta: { title: '语音识别' }, component: () => import('../views/speech_recognition.vue') },
  // 果果慧故事
  { path: '/story', name: 'story', meta: { title: '果果慧故事' }, component: () => import('../views/story.vue') },
  // 加入我们
  { path: '/join', name: 'join', meta: { title: '加入我们' }, component: () => import('../views/join.vue') },
  // 联系我们
  { path: '/concat', name: 'concat', meta: { title: '联系我们' }, component: () => import('../views/concat.vue') },
  // 产品列表
  { path: '/products', name: 'products', meta: { title: '产品列表' }, component: () => import('../views/products.vue') },
  // 法律申明
  { path: '/law', name: 'law', meta: { title: '法律申明' }, component: () => import('../views/law.vue') },
  // 隐私政策
  { path: '/privacy', name: 'privacy', meta: { title: '隐私政策' }, component: () => import('../views/privacy.vue') },
  // 使用条款
  { path: '/clause', name: 'clause', meta: { title: '使用条款' }, component: () => import('../views/clause.vue') },
  // 网站问题反馈
  { path: '/feedback', name: 'feedback', meta: { title: '网站问题反馈' }, component: () => import('../views/feedback.vue') },
  // 404处理
  { path: '/404', name: '404', meta: { title: '地址出错' }, component: () => import('../views/404.vue'), hidden: true},
  { path: '*', redirect: '/404',  hidden: true },
]
const router = new VueRouter({
  // mode: 'history',
  routes: constantRouterMap,
  // scrollBehavior(to, from) {
  //   from.meta.scrollTop = window.scrollY;
  //   return { x: 0, y: to.meta.scrollTop || 0 };
  // }
});

router.beforeEach((to, form, next) => {
  const { title }  = to.meta;
  // constantRouterMap
  // console.log(constantRouterMap);
  document.title = title || process.env.VUE_APP_NAME || "果果慧科技";
  next();
});

export default router;
