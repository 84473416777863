// 英文
export default {
  platformName: 'vue-i18n + element-ui multilingual configuration',
  logIn: 'log in',
  logOut: 'log out',
  register: 'register',
  normalLogin: 'normal login',
  phoneLogin: 'quick phone login',
  userName: 'user name',
  password: 'password',
  phone: 'phone number',
  verificationCode: 'verification code',
  getCode: 'get code',
  enterCode: 'Please enter a verification code',
  enterPhone: 'Please enter your mobile phone number',
  enterUserName: 'Please enter a user name',
  enterPassword: 'Please enter password',
  enterLoginPassword: 'Please enter your login password',
  enterRightPhone: 'Please enter the correct mobile phone number',
  title: 'abcdefghijklmn'
}
