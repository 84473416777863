// 繁体中文
export default {
  platformName: 'vue-i18n + element-ui 多语言配置',
  logIn: '登錄',
  logOut: '退出',
  register: '註冊',
  normalLogin: '普通登錄',
  phoneLogin: '手機快捷登錄',
  userName: '用戶名',
  password: '密碼',
  phone: '手機號',
  verificationCode: '驗證碼',
  getCode: '獲取驗證碼',
  enterCode: '請輸入驗證碼',
  enterPhone: '請輸入手機號碼',
  enterUserName: '請輸入用戶名',
  enterPassword: '請輸入密碼',
  enterLoginPassword: '請輸入登錄密碼',
  enterRightPhone: '請輸入正確的手機號碼',
  title: '繁体字标题展示'
}
