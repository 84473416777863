const state = {
  languageStr: 'zhCN',
  urlStr:'/'
};

const mutations = {
  SET_LANGUAGE(state, lang) {
    state.languageStr = lang;
  },
  SET_URL(state, urlStr) {
    state.urlStr = urlStr;
  },
};

const actions = {
};

export default {
  state,
  mutations,
  actions
};
